'use client';
import { State } from './globalContext';
import persistenceService from '@/lib/utils/persistenceService';
import { ActionType, OffersResponse, STORAGE_KEY } from './actions';
import { offersPayloadMapper } from './offersPayloadMapper';
import { Applicant } from '@/lib/types/OffersPayload.types';
import { JourneyFlow } from '@/v2Lib/journeyFlow';

type Action = {
  type: ActionType;
  payload: any;
};

export type ActionUpdateOffersResponse = {
  type: ActionType;
  payload: OffersResponse;
};

const globalStoreReducer = (
  state: State,
  action: Action & ActionUpdateOffersResponse,
) => {
  switch (action.type) {
    case ActionType.UPDATE_OFFERS_PAYLOAD: {
      const existingOfferPayload = state.offersPayload;
      const partner = state.partner;
      const mappedPayload = offersPayloadMapper(
        action.payload,
        partner,
        existingOfferPayload,
      );

      const newState = {
        ...state,
        offersPayload: mappedPayload,
      };

      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.UPDATE_COMPLETED_STAGES: {
      const completedStages = state.completedStages;
      const newCompleteStages = { ...completedStages, ...action.payload };
      const newState = { ...state };
      newState.completedStages = newCompleteStages;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.UPDATE_OFFER_RESPONSE: {
      const newState = { ...state };
      newState.offersResponse = action.payload;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.UPDATE_OFFER_SELECTED: {
      const newState = { ...state };
      newState.offerSelected = { ...action.payload };
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.REMOVE_SELECTED_OFFER: {
      const newState = { ...state };
      delete newState.offerSelected;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.ACCEPT_OFFER: {
      const newState = { ...state };
      newState.acceptedOffer = { ...action.payload };
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.ADD_SESSION_ID: {
      const newState = { ...state };
      newState.sessionId = action.payload.sessionId;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.REMOVE_SHAREHOLDERS: {
      const newState = { ...state };
      newState.offersPayload.applicants = newState.offersPayload.applicants
        .filter((applicant: Applicant) => applicant.primary)
        .map((applicant: Applicant) => {
          const { ownership_percentage, ...rest } = applicant;
          return rest;
        });
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.REMOVE_PARTNER: {
      const newState = { ...state };
      newState.offersPayload.applicants =
        newState.offersPayload.applicants.filter(
          (applicant: Applicant) => applicant.primary,
        );
      return newState;
    }
    case ActionType.UPDATE_ACCEPT_PAYLOAD: {
      const newState = { ...state };
      newState.acceptPayload = action.payload;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.HAS_SIGNED_ALL_CONTRACTS: {
      const newState = { ...state };
      newState.hasSignedAllContracts = action.payload;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.HAS_CONNECTED_OPEN_BANKING: {
      const newState = { ...state };
      newState.hasConnectedOpenBanking = action.payload;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.UPDATE_CREATE_CODAT_COMPANY_RESPONSE: {
      const newState = { ...state };
      newState.createCodatCompanyResponse = action.payload;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.SET_INDICATIVE_QUOTING_FALLBACK: {
      const newState = { ...state };
      newState.unknownMID = action.payload;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.SET_VALIDATED_MID: {
      const newState = { ...state };
      newState.validatedMID = action.payload;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.RESET_MID_AND_VOLUMES: {
      const newState = { ...state };
      newState.offersPayload.application.merchant_id = '';

      if (newState?.offersPayload?.company?.monthly_revenues) {
        delete newState.offersPayload.company.monthly_revenues;
      }
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.EDIT_MODE: {
      const newState = { ...state };

      newState.editMode = true;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.ALLOW_ENTITY_SELECT: {
      const newState = { ...state };

      newState.allowEntitySelect = true;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.ALLOW_BUSINESS_NAME_SELECT: {
      const newState = { ...state };

      newState.allowBusinessNameSelect = true;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.ALLOW_PERSONAL_NAME_SELECT: {
      const newState = { ...state };

      newState.allowPersonalNameSelect = true;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.REMOVE_EDIT_MODE: {
      const newState = { ...state };

      newState.editMode = false;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.UPDATE_INDIVIDUAL_MEDIUM_CONSENT: {
      const newState = { ...state };

      newState.individualMediumConsent = action.payload;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.UPDATE_EXPLICIT_MARKETING_CONSENT: {
      const newState = { ...state };

      newState.explicitMarketingConsent = action.payload;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.UPDATE_DISCLOSURE: {
      const newState = { ...state };

      newState.disclosure = action.payload;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.UPDATE_ACCEPT_PAYLOAD_BODY: {
      const newState = { ...state };

      newState.acceptPayloadBody = action.payload;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.UPDATE_ACCEPT_RESPONSE: {
      const newState = { ...state };

      newState.acceptResponse = action.payload;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.UPDATE_SAME_ADDRESS: {
      const newState = { ...state };

      newState.sameAddress = action.payload;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.UPDATE_ORIGINAL_DATA: {
      const newState = { ...state };

      newState.partnerProvidedData = action.payload;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.UPDATE_CONTRACT_DETAILS: {
      const newState = { ...state };

      newState.contractDetails = action.payload;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.UPDATE_EXPANDED_INDUSTRY: {
      const newState = { ...state };

      newState.expandedIndustry = action.payload;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.SET_APPLICATION_COMPLETE: {
      const newState = { ...state };
      newState.applicationComplete = true;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.SET_JOURNEY_FLOW: {
      const newState = {
        ...state,
        journeyFlow: action.payload.journeyFlow ?? JourneyFlow.BCA,
      };
      return newState;
    }
    case ActionType.SET_SELECTED_RENEWAL_OFFER: {
      const newState = { ...state };
      newState.renewalOfferSelected = action.payload;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.SET_ACCEPTED_RENEWAL_OFFER: {
      const newState = { ...state };
      newState.renewalOfferAccepted = action.payload;
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    case ActionType.ADD_RENEWAL_DATA: {
      const renewalsData = state?.renewalsData || {};
      const newState = {
        ...state,
        renewalsData: {
          ...renewalsData,
          ...action.payload,
        },
      };
      persistenceService.set(STORAGE_KEY, newState);
      return newState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export default globalStoreReducer;
