import { BusinessType } from './types/BusinessType.types';

export const businessTypeValues: { [k: string]: BusinessType } = {
  sole_trader: 'Sole Trader',
  limited_company: 'Limited Company',
  limited_partnership: 'Limited Partnership',
  llp: 'LLP',
  partnership: 'Partnership',
  sole_proprietor: 'Sole Proprietor',
  llc: 'LLC',
  other_corporation: 'Other Corporation',
  // German types
  einzelkaufmann: 'Einzelkaufmann',
  gmbh: 'GmbH',
  mbh: 'mbH',
  ug: 'UG',
  gbr: 'GbR',
  gmbh_co_kg: 'GmbH & Co. KG',
  kg: 'KG',
  ohg: 'OHG',
};

// if adding a businessType, don't forget to add to OffersPayloadValidation.ts 

export const journeyPathsByBusinessType = {
  [businessTypeValues.sole_trader]: [
    'business-details',
    'ownership-details',
    'your-quote',
    'review-terms',
    'application-submitted',
  ],
  [businessTypeValues.limited_company]: [
    'business-details',
    'ownership-details',
    'your-quote',
    'review-terms',
    'application-submitted',
  ],
  [businessTypeValues.partnership]: [
    'business-details',
    'ownership-details',
    'your-quote',
    'review-terms',
    'application-submitted',
  ],
  ['leadCapture']: ['business-details', 'application-submitted'],
};

export const businessTypeFamilies = {
  [businessTypeValues.sole_trader]: [
    businessTypeValues.sole_trader,
    businessTypeValues.sole_proprietor,
    businessTypeValues.einzelkaufmann,
  ],
  [businessTypeValues.limited_company]: [
    businessTypeValues.limited_company,
    businessTypeValues.llc,
    businessTypeValues.other_corporation,
    businessTypeValues.limited_partnership,
    businessTypeValues.gmbh,
    businessTypeValues.mbh,
    businessTypeValues.ug,
  ],
  [businessTypeValues.partnership]: [
    businessTypeValues.partnership,
    businessTypeValues.gbr,
    businessTypeValues.gmbh_co_kg,
    businessTypeValues.kg,
    businessTypeValues.ohg,
  ],
};

export const getBusinessTypeFamily = (
  businessType: BusinessType,
): string => {
  if (!businessType) return '';
  for (const family in businessTypeFamilies) {
    if (businessTypeFamilies[family].includes(businessType)) {
      return family;
    }
  }
  throw new Error('businessType value from form does not match our types');
};
