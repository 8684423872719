'use client';

import React, { useReducer } from 'react';
import GlobalStoreStateContext, { State } from './globalContext';
import globalStoreReducer from './reducer';
import { usePartner } from '@/lib/partners/usePartner/client';
import persistenceService from '@/lib/utils/persistenceService';
import { STORAGE_KEY } from './actions';

type GlobalStoreProviderProps = {
  children: React.ReactNode;
  initialValue?: State;
};

function GlobalStoreProvider({
  children,
  initialValue,
}: GlobalStoreProviderProps): JSX.Element {
  const partner = usePartner();

  const persistedState: State = persistenceService.get(STORAGE_KEY)
    ? persistenceService.get(STORAGE_KEY)
    : {};

  const initialState = initialValue ?? persistedState;

  const [state, dispatch] = useReducer(globalStoreReducer, {
    ...initialState,
    partner: partner,
  });

  const value = { state, dispatch };
  return (
    <GlobalStoreStateContext.Provider value={value}>
      {children}
    </GlobalStoreStateContext.Provider>
  );
}

export default GlobalStoreProvider;
